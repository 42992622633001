<template>
    <div class="loginWrap">
        <!-- <div class="headerContent-black content">
            <div class="logo">
                <router-link to="/home" tag="a"><img src="../../assets/images/w-logo.png"></router-link>
            </div>
            <div class="right">
                <el-dropdown type="primary" trigger="hover">
                    <div class="lang" split-button>{{defaultLang}}</div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item,index) in langList" :key="index" @click.native="checkLang(item)">{{item.text}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div> -->
        <video class="loginView" src="../../assets/video/logo-bg.mp4" autoplay loop muted></video>
        <div class="loginContent" @click="forgetView = false">
            <div class="flexInput">
                <div class="rightForm">
                    <div class="rightLang">
                        <el-dropdown type="primary" trigger="hover">
                            <div class="lang" split-button>{{defaultLang}}</div>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="(item,index) in langList" :key="index" @click.native="checkLang(item)">{{item.text}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="loginLogo"><img src="../../assets/images/login-logo.png"></div>
                    <!-- <h3 class="title">{{$t('oa.Welcome')}}</h3> -->

                    <el-form :label-position="labelPosition" ref="loginForm" :rules="Loginrules" :model="loginForm" class="loginForm">
                        <el-form-item style="margin-bottom: 40px;">
                            <div class="checkType">
                                <div class="itemType" v-for="(element,index) in checkTypeList" :class="{active:checkouLoginType == index}" @click="checkoutType(index)" :key="index">{{element.text}}</div>
                            </div>
                        </el-form-item>
                        <el-form-item prop="username">
                            <el-input v-model="loginForm.username" :placeholder="$t('oa.Account')"></el-input>
                        </el-form-item>

                        <el-form-item prop="password">
                            <el-input v-model="loginForm.password" type="password" :placeholder="$t('oa.Password')"></el-input>
                        </el-form-item>
                        
                        <el-form-item prop="captcha">
                            <el-input v-model="loginForm.captcha" :placeholder="$t('oa.Verification')"></el-input>
                            <el-button class="authCode" @click="sendLoginCode" :loading="smsLogind" :disabled="sendMsgDisabled">
                                <span v-if="sendMsgDisabled">{{loginTimer}}{{$t('oa.retry')}}</span>
                                <span v-if="!sendMsgDisabled">{{$t('oa.sendVerification')}}</span>
                            </el-button>
                            <!-- <img :src="captchaPath">  -->
                        </el-form-item>
                        <el-form-item class="submit">
                            <el-button class="submitButton submitBtn" @click="submitForm('loginForm')" round :disabled="loadingSubmit" :loading="loadingSubmit">{{$t('oa.Sign')}}</el-button>
                        </el-form-item>
                        <div @click.stop="setForgetPassword" class="forgetClick">{{$t('oa.forgetPassword')}} ?</div>
                    </el-form>
                    <div class="policyinforWrap">
                        <span><i>*</i>{{$t('oa.footerInfor')}}<span class="policyinfor" @click="centerDialogVisible = true">{{$t('oa.Privacypolicy')}}</span></span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <Canvasbg /> -->
        <el-dialog append-to-body :visible.sync="centerDialogVisible" title="Privacy Policy" width="50%" center>
            <privacyP />
        </el-dialog>
        <transition name="forget">
            <div class="forgetPassword" v-if="forgetView">
                <div class="close" @click="setForgetPassword"> <span class="el-icon-close"></span></div>
                <div class="title">{{$t('oa.forgetPassword')}}</div>
                
                <el-form :label-position="labelPosition" ref="forgetPWD" :rules="forgetRules" :model="forgetPWD" class="forgetPWD">
                    <el-form-item style="margin-bottom: 40px;">
                         <div class="checkType">
                            <div class="itemType" v-for="(element,index) in checkTypeList" :class="{active:checkouLoginType == index}" @click="checkoutType(index)" :key="index">{{element.text}}</div>
                        </div>
                    </el-form-item>
                    <el-form-item :prop="checkouLoginType==1 ? 'email':'mobile' ">
                        <el-input v-model="forgetPWD.email" :placeholder="$t('oa.emailCus')" v-if="checkouLoginType == 1"></el-input>
                        <el-input v-model="forgetPWD.mobile" :placeholder="$t('oa.phone')" v-else></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="forgetPWD.password" type="password" :placeholder="$t('oa.newPassword')"></el-input>
                    </el-form-item>
                    <el-form-item prop="confirmPassword">
                        <el-input v-model="forgetPWD.confirmPassword" type="password" :placeholder="$t('oa.confoimNewPassword')"></el-input>
                    </el-form-item>
                    <el-form-item prop="captcha">
                        <el-input v-model="forgetPWD.captcha" :placeholder="$t('oa.Verification')"></el-input>
                        <el-button class="authCode" @click="sendForgotCode" :disabled="sendMsgDisabledForgot" :loading="smsForgot">
                            <span v-if="sendMsgDisabledForgot">{{forgotTimer}}{{$t('oa.retry')}}</span>
                            <span v-if="!sendMsgDisabledForgot">{{$t('oa.sendVerification')}}</span>
                        </el-button>
                    </el-form-item>
                </el-form>
                <!-- <div class="infor" v-html="$t('oa.forgetTips')">
                </div> -->
                <el-button class="nextStep" @click="submitForgetForm(`forgetPWD`)" :disabled="submitLoading" :loading="submitLoading"><span>{{$t('oa.submit')}}</span></el-button>
            </div>
        </transition>
    </div>
</template>
<script>
import url from "@/api/url.js";
import Canvasbg from "@/components/partitcle.vue";
import privacyP from "@/components/privacyPolicy.vue";
export default {
    components: {
        Canvasbg,
        privacyP,
    },
    data() {
        let validateRepassword = (rule, value, callback) => {
            if (value === "") {
                callback(new Error(this.$t("oa.enterPasswordAgain")));
            } else if (value !== this.forgetPWD.password) {
                callback(new Error(this.$t("oa.passwordsAreInconsistent")));
            } else {
                callback();
            }
        };
        return {
            forgetView: false,
            loadingSubmit: false,
            centerDialogVisible: false,
            submitLoading: false,
            checkouLoginType: 0,
            labelPosition: "right",
            captchaPath: "",
            sendMsgDisabled: false,
            sendMsgDisabledForgot: false,
            loginTimer: 60,
            forgotTimer: 60,
            smsLogind: false,
            smsForgot: false,
            defaultLang: "CN",
            forgetPWD: {
                captcha: "",
                email: "",
                mobile: "",
                password: "",
                confirmPassword: "",
            },
            langList: [
                {
                    text: "English",
                    lang: "EN",
                },
                {
                    text: "中文繁体",
                    lang: "TC",
                },
                {
                    text: "中文简体",
                    lang: "CN",
                },
            ],
            forgetRules: {
                email: [
                    { required: true, message: "请输入邮箱", trigger: "blur" },
                ],
                mobile: [
                    { required: true, message: "请输入手机", trigger: "blur" },
                ],
                captcha: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    },
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                ],
                confirmPassword: [
                    { validator: validateRepassword, trigger: "blur" },
                ],
            },
            checkTypeList: [
                {
                    text: "手机验证",
                },
                {
                    text: "邮箱验证",
                },
            ],

            loginForm: {
                username: "",
                password: "",
                captcha: "",
            },
            Loginrules: {
                username: [
                    { required: true, message: "请输入账号", trigger: "blur" },
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                ],
                captcha: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        const lang = localStorage.getItem("AUTLang");
        this.$i18n.locale = lang || "CN";
        this.defaultLang = lang || "CN";
        this.initLang();
    },
    watch: {
        "$i18n.locale": function (newVal) {
            this.initLang();
        },
        checkouLoginType: function (newVal) {
            if (newVal == 1) {
                this.forgetRules.email[0].message = this.$t("oa.enteremail");
            } else {
                this.forgetRules.mobile[0].message = this.$t("oa.enterPhone");
            }
        },
    },
    methods: {
        setForgetPassword() {
            this.forgetView = !this.forgetView;
        },
        initLang() {
            this.forgetRules.email[0].message = this.$t("oa.enteremail");
            this.forgetRules.mobile[0].message = this.$t("oa.enterPhone");
            this.forgetRules.captcha[0].message = this.$t(
                "oa.enterVerification"
            );
            this.forgetRules.password[0].message = this.$t("oa.enterPassword");
            this.Loginrules.username[0].message = this.$t("oa.enterAccount");
            this.Loginrules.password[0].message = this.$t("oa.enterPassword");
            this.Loginrules.captcha[0].message = this.$t(
                "oa.enterVerification"
            );
            this.checkTypeList[1].text = this.$t("oa.emialverification");
            this.checkTypeList[0].text = this.$t("oa.smsverification");
        },
        checkoutType(index) {
            this.checkouLoginType = index;
        },
        submitForm(formName) {
            if (this.loadingSubmit) return;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitLogin(formName);
                } else {
                    return false;
                }
            });
        },
        checkLang(e) {
            this.defaultLang = e.lang;
            this.$i18n.locale = e.lang;
            localStorage.setItem("AUTLang", e.lang);
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        submitLogin(formName) {
            this.loadingSubmit = true;
            this.loginForm.type = this.checkouLoginType == 0 ? 1 : 2;
            this.$axios
                .post(url.login, {
                    ...this.loginForm,
                    loginDate: this.getDataTime(1),
                    loginTime: this.getDataTime(2),
                })
                .then((res) => {
                    localStorage.setItem("AUTtoken", res.token);
                    localStorage.setItem("userName", this.loginForm.username);
                    this.resetForm(formName);
                    this.$router.replace({ name: "Home" });
                })
                .finally(() => {
                    this.loadingSubmit = false;
                });
        },
        sendLoginCode() {
            let self = this;
            if (!this.loginForm.username) {
                this.$message({
                    showClose: true,
                    message: self.$t("oa.enterUserName"),
                    type: "error",
                });
                return;
            }
            this.smsLogind = true;
            let SendUrl =
                this.checkouLoginType == 1
                    ? url.loginEmialCode
                    : url.loginMoblieCode;
            this.$axios
                .post(SendUrl, {
                    username: this.loginForm.username,
                })
                .then((res) => {
                    this.sendMsgDisabled = true;
                    this.$message({
                        showClose: true,
                        message: self.$t("oa.emailSuccess"),
                        type: "success",
                    });
                    let interval = window.setInterval(function () {
                        if (self.loginTimer-- <= 0) {
                            self.loginTimer = 60;
                            self.sendMsgDisabled = false;
                            window.clearInterval(interval);
                        }
                    }, 1000);
                })
                .finally(() => {
                    this.smsLogind = false;
                });
        },
        sendForgotCode() {
            let self = this;
            var params;
            if (this.checkouLoginType == 1) {
                if (!this.forgetPWD.email) {
                    this.$message({
                        showClose: true,
                        message: this.$t("oa.enteremail"),
                        type: "error",
                    });
                    return;
                } else {
                    params = { email: this.forgetPWD.email };
                }
            } else {
                if (!this.forgetPWD.mobile) {
                    this.$message({
                        showClose: true,
                        message: this.$t("oa.enterPhone"),
                        type: "error",
                    });
                    return;
                } else {
                    params = { mobile: this.forgetPWD.mobile };
                }
            }
            this.smsForgot = true;
            let SendUrl =
                this.checkouLoginType == 1 ? url.forgetCodeE : url.forgetCodeM;
            this.$axios
                .post(SendUrl, params)
                .then((res) => {
                    this.$message({
                        showClose: true,
                        message: this.$t("oa.emailSuccess"),
                        type: "success",
                    });
                    this.sendMsgDisabledForgot = true;
                    let interval = window.setInterval(function () {
                        if (self.forgotTimer-- <= 0) {
                            self.forgotTimer = 60;
                            self.sendMsgDisabledForgot = false;
                            window.clearInterval(interval);
                        }
                    }, 1000);
                })
                .finally(() => {
                    this.smsForgot = false;
                });
        },

        submitForget(formName) {
            this.submitLoading = true;
            this.$axios
                .post(url.forgetPassword, {
                    email: this.forgetPWD.email,
                    mobile: this.forgetPWD.mobile,
                    code: this.forgetPWD.captcha,
                    password: this.forgetPWD.password,
                })
                .then((res) => {
                    this.$message({
                        showClose: true,
                        message: this.$t("oa.Resetsuccess"),
                        type: "success",
                    });
                })
                .finally(() => {
                    this.submitLoading = false;
                    this.resetForm(formName);
                });
        },

        submitForgetForm(formName) {
            if (this.forgetLoading) return;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitForget(formName);
                } else {
                    return false;
                }
            });
        },

        getDataTime(type) {
            var date = new Date();
            var year = date.getFullYear(); //年 ,从 Date 对象以四位数字返回年份
            var month = date.getMonth() + 1; //月 ,从 Date 对象返回月份 (0 ~ 11) ,date.getMonth()比实际月份少 1 个月
            var day = date.getDate(); //日 ,从 Date 对象返回一个月中的某一天 (1 ~ 31)

            var hours = date.getHours(); //小时 ,返回 Date 对象的小时 (0 ~ 23)
            var minutes = date.getMinutes(); //分钟 ,返回 Date 对象的分钟 (0 ~ 59)
            var seconds = date.getSeconds(); //秒 ,返回 Date 对象的秒数 (0 ~ 59)
            //获取当前系统时间
            var currentDate =
                year +
                "-" +
                month +
                "-" +
                day +
                " " +
                hours +
                ":" +
                minutes +
                ":" +
                seconds;
            // alert(currentDate);

            //修改月份格式
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }

            //修改日期格式
            if (day >= 0 && day <= 9) {
                day = "0" + day;
            }

            //修改小时格式
            if (hours >= 0 && hours <= 9) {
                hours = "0" + hours;
            }

            //修改分钟格式
            if (minutes >= 0 && minutes <= 9) {
                minutes = "0" + minutes;
            }

            //修改秒格式
            if (seconds >= 0 && seconds <= 9) {
                seconds = "0" + seconds;
            }

            //获取当前系统时间  格式(yyyy-mm-dd hh:mm:ss)
            var currentFormatDate =
                year +
                "-" +
                month +
                "-" +
                day +
                " " +
                hours +
                ":" +
                minutes +
                ":" +
                seconds;
            if (type == 1) {
                return year + "-" + month + "-" + day;
            } else {
                return hours + ":" + minutes + ":" + seconds;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/.checkBox {
    margin-bottom: 15px !important;
}
.checkType {
    display: flex;
    justify-content: space-around;
    // height: 50px;
    border-bottom: 2px solid #bfc5d1;
    width: 100%;
    margin-top: 20px;
    .itemType {
        flex: 1;
        cursor: pointer;
        font-size: 14px;
        height: 50px;
        line-height: 50px;
        color: #bfc5d1;
        position: relative;
        text-align: center;
        transform: translateY(2px);
        position: relative;
    }
    .itemType.active::before {
        // background-color: #305a9e;
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        border-radius: 4px;
        display: block;
        color: #305a9e;
        border-bottom: 6px solid #305a9e;
    }
    .itemType.active{
        color: #305a9e;
    }
}
.forgetPassword {
    width: 50%;
    position: fixed;
    box-sizing: border-box;
    padding-top: 100px;
    padding-left: 80px;
    border-top:10px solid #305a9e;
    overflow: auto;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 100;

    .infor {
        margin-top: 50px;
    }
    .title {
        font-size: 42px;
        font-weight: bold;
        padding-bottom: 50px;
    }
    .close {
        position: absolute;
        top: 100px;
        right: 40px;
        z-index: 8;
        cursor: pointer;
        span {
            font-size: 30px;
        }
    }
    /deep/.el-input__inner {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #676767;
        border-radius: 0;
        color: #000;
        &::placeholder {
            color: #999;
        }
    }
    /deep/.el-form {
        width: 500px;
    }
    .authCode {
        height: 50px;
        border-radius: 50px;
        margin-left: 10px;
        background: linear-gradient(90deg, #305a9e 0%, #0e6fa7 100%);
        color: #fff;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        border: none;
    }
    /deep/.el-form-item__content {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .nextStep {
        width: 500px;
        margin-top: 10px;
        background-color: #305a9e;
        border-radius: 30px;
        color: #fff;
        border: none;
        height: 50px;
        span {
            font-size: 22px !important;
        }
    }
}
.Privacypolicycontent {
    p {
        line-height: 25px;
        margin-top: 20px;
    }
    h3 {
        font-size: 18px;
    }
}
.confirm {
    background-color: #305a9e;
    border-color: #305a9e;
    color: #fff;
}
.loginWrap {
    position: relative;
    .loginView {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        z-index: 9;
    }
    /deep/.el-dialog__title {
        font-size: 36px;
        font-weight: bold;
    }
    /deep/.el-dialog__header {
        padding: 50px 40px 10px;
    }
    .rightForm {
        background-color: #fff;
        // padding: 15px 30px ;
        padding-top: 40px;
        width: 500px;
        border-radius: 22px;
        border-bottom: 10px solid #305a9e;
        position: relative;
        .rightLang {
            position: absolute;
            top: 30px;
            right: 30px;
            .el-dropdown {
                display: flex;
                align-items: center;
                .lang {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #000;
                    border-radius: 50%;
                    border: 2px solid #000;
                    font-size: 12px;
                    font-weight: bold;
                    width: 28px;
                    height: 28px;
                }
                cursor: pointer;
                align-items: center;
                .ic {
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                }
            }
        }
        .loginLogo {
            text-align: center;
            img {
                margin: 0 auto;
            }
        }
        .forgetClick {
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 15px;
            cursor: pointer;
            text-align: center;
        }
    }
    .policyinforWrap {
        color: #333;
        text-align: center;
        width: 100%;
        padding: 0 40px;
        padding-bottom: 40px;
        font-weight: bold;
        line-height: 25px;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .policyinfor {
            color: #305a9e;
            cursor: pointer;
            margin-left: 5px;
            text-decoration: underline;
        }
        .policyinfor:hover {
            color: #305a9e;
        }
        /deep/i {
            color: #f00;
            margin-right: 10px;
            font-style: normal;
        }
    }
    .leftImg {
        width: 660px;
        display: flex;
        align-items: center;
        padding-right: 120px;
    }
    /deep/.el-input__inner {
        background-color: transparent;
        border: none;
        height: 50px;
        border: 2px solid #bfc5d1;
        border-radius: 40px;
        color: #333;
        &::placeholder {
            color: #bfc5d1;
        }
    }
    /deep/.loginForm {
        padding: 0px 50px;
    }
    .loginContent {
        width: 1320px;
        height: 100vh;
        position: relative;
        z-index: 9;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .title {
            // line-height: 120px;
            font-size: 28px;
            color: #fff;
            text-align: center;
            font-weight: bold;
            color: #000;
            padding: 25px 0;
        }
        .submit {
            width: 100%;
            display: flex;
            justify-content: space-between;
            // margin-top: 40px;
            /deep/.el-form-item__content {
                display: flex;
                justify-content: space-between;
                width: 100%;
                span {
                    font-size: 24px !important;
                }
                .el-icon-loading {
                    font-size: 24px !important;
                }
            }
        }
        .submitButton {
            width: 100%;
            display: block;
            background-color: #305a9e;
            border-color: #305a9e;
            color: #fff;
            border-radius: 30px;
        }

        .reastBtn {
            color: #305a9e;
        }
        /deep/.el-form-item__content {
            display: flex;
            .el-input {
                flex: 1;
            }
        }
        /deep/.el-form-item {
            margin-bottom: 28px;
        }

        .authCode {
            height: 50px;
            border-radius: 50px;
            margin-left: 10px;
            line-height: 0;
            background-color: #305a9e;
            color: #fff;
            text-align: center;
            font-weight: bold;
            cursor: pointer;
            border: none;
            span {
                font-size: 14px;
            }
        }
    }
    .headerContent-black {
        width: 1320px;
        height: 100px;
        position: absolute;
        z-index: 99;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
</style>